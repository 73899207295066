import axios from "axios";
//const bearerToken = localStorage.getItem("access_token");
const api = axios.create({
  //baseURL: "https://saimo-back.vercel.app/",
  //baseURL: "http://localhost:3000/",
  baseURL: "lgpd-api",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
