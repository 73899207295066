import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "../Pages/Index";
import PoliticaPrivacidade from "../Pages/Politicas";

function Rotas() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/Política-de-Privacidade" element={<PoliticaPrivacidade />} />
      </Routes>
    </div>
  );
}

export default Rotas;
