import React from "react";

function PoliticaPrivacidade() {
  const styles = {
    body: {
      fontFamily: "Arial, sans-serif",
      margin: 0,
      padding: 0,
    },
    container: {
      width: "80%",
      margin: "auto",
      backgroundColor: "#ffffff",
      padding: 20,
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    },
    header: {
      color: "#333",
      textAlign: "center",
    },
    paragraph: {
      lineHeight: 1.6,
      margin: "0 0 20px 0",
      marginLeft: "auto",
      marginRight: "auto",
      width: "80vw",
      maxWidth: 900,
      color: "#000",
      textAlign: "center",
    },
    list: {
      lineHeight: 1.6,
      margin: "0 0 20px 0",
      color: "#000",
      marginLeft: "auto",
      marginRight: "auto",
      width: "80vw",
      maxWidth: 900,
    },
  };
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>POLÍTICA DE PRIVACIDADE DE DADOS PESSOAIS</h1>
      <p style={styles.paragraph}>
        A Prefeitura Municipal de Uberlândia, com endereço localizado na Av.
        Anselmo Alves dos Santos, nº 600, Bairro Santa Mônica, Uberlândia/MG,
        CEP 38.408-900, preza pela segurança dos seus dados pessoais, pelo
        respeito a sua privacidade e pela transparência com você e, por isso,
        dedicamos este documento para explicar como os seus dados pessoais serão
        tratados e quais são as medidas que aplicamos para mantê-los seguros,
        para além da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados
        Pessoais).
      </p>

      <h2 style={styles.header}>
        ATUALIZAÇÃO DA POLÍTICA DE PRIVACIDADE DE DADOS PESSOAIS
      </h2>
      <p style={styles.paragraph}>
        Sempre que a Prefeitura Municipal de Uberlândia entender necessário, a
        Política de Privacidade de Dados Pessoais poderá sofrer alterações que
        serão publicadas em nosso Portal. Essas alterações serão válidas,
        eficazes e vinculantes após a nova versão a ser divulgada ou serem
        comunicadas de qualquer outra forma a você.
      </p>

      <h2 style={styles.header}>GLOSSÁRIO</h2>

      <li style={styles.list}>
        <strong>Anonimização:</strong> utilização de meios técnicos razoáveis e
        disponíveis no momento do tratamento, por meio dos quais um dado perde a
        possibilidade de associação, direta ou indireta, a um indivíduo;
      </li>
      <li style={styles.list}>
        <strong>Controlador:</strong> pessoa natural ou jurídica, de direito
        público ou privado, a quem competem as decisões referentes ao Tratamento
        de Dados Pessoais;
      </li>
      <li style={styles.list}>
        <strong>Dado Pessoal:</strong> informação relacionada a pessoa natural
        identificada ou identificável, tal como nome, endereço residencial,
        endereço de e-mail, número de CPF, número de RG, título de eleitor,
        número de telefone(s), profissão, data de nascimento, estado civil,
        nacionalidade, entre outros;
      </li>
      <li style={styles.list}>
        <strong>Dado Pessoal Sensível:</strong> dado pessoal sobre origem racial
        ou étnica, convicção religiosa, opinião política, filiação a sindicato
        ou a organização de caráter religioso, filosófico ou político, dado
        referente à saúde ou à vida sexual, dado genético ou biométrico, quando
        vinculado a uma pessoa natural;
      </li>
      <li style={styles.list}>
        <strong>Encarregado de Proteção de Dados:</strong> Pessoa indicada pela
        Administração da Prefeitura Municipal de Uberlândia para atuar como
        canal de comunicação entre o controlador, os titulares dos Dados
        Pessoais e a Autoridade Nacional de Proteção de Dados (ANPD);
      </li>
      <h2 style={styles.header}>COLETA E TRATAMENTO DE DADOS</h2>
      <p style={styles.paragraph}>
        A Prefeitura Municipal de Uberlândia é a entidade responsável pela
        coleta e tratamento dos dados pessoais dos Titulares.
      </p>
      <p style={styles.paragraph}>
        Esta Política de Privacidade de Dados Pessoais informa como tratamos os
        seus dados pessoais. Também define os seus direitos em relação aos seus
        dados pessoais e quem você pode contatar para obter mais informações ou
        esclarecimentos a respeito.
      </p>

      <h2 style={styles.header}>DIREITOS DO TITULAR</h2>
      <p style={styles.paragraph}>
        Nos termos da legislação aplicável, o titular do dado poderá fazer os
        seguintes requerimentos:
      </p>
      <ul>
        <li style={styles.list}>
          acesso e confirmação da existência de tratamento dos dados pessoais;
        </li>
        <li style={styles.list}>
          atualização, correção de dados incompletos, inexatos ou
          desatualizados;
        </li>
        <li style={styles.list}>
          anonimização, bloqueio ou eliminação de dados desnecessários,
          excessivos ou tratados em desconformidade com o disposto na LGPD;
        </li>
        <li style={styles.list}>
          portabilidade dos dados pessoais, observadas as normas aplicáveis e os
          segredos comercial e industrial;
        </li>
        <li style={styles.list}>
          informação das entidades públicas e privadas com as quais o
          Controlador faz o uso compartilhado de dados;
        </li>
        <li style={styles.list}>
          informação sobre a possibilidade de não fornecer consentimento e sobre
          as consequências da negativa;
        </li>
        <li style={styles.list}>
          revogação de consentimento que poderá ser realizada a qualquer momento
          e sem ônus, mediante manifestação expressa;
        </li>
        <li style={styles.list}>
          solicitar a revisão de decisões tomadas unicamente com base em
          Tratamento automatizado de dados pessoais que o afetem.
        </li>
      </ul>
      <p style={styles.paragraph}>
        Se você tiver alguma dúvida, observação, solicitação ou reclamação sobre
        a coleta ou o uso de seus dados pessoais ou sobre esta Política de
        Privacidade e Proteção de Dados Pessoais, você poderá exercer seus
        direitos mediante pedido escrito a um dos endereços abaixo:
      </p>

      <p style={styles.paragraph}>
        Prefeitura Municipal de Uberlândia Av. Anselmo Alves dos Santos, nº 600,
        Bairro Santa Mônica, Uberlândia/MG, CEP 38.408-900
      </p>

      <p style={styles.paragraph}>OU</p>

      <p style={styles.paragraph}>E-mail: contato.lgpd@uberlandia.mg.gov.br</p>

      <p style={styles.paragraph}>
        Reforçamos que poderemos manter alguns dados e/ou continuar a realizar o
        Tratamento, mesmo no caso de solicitação de eliminação, oposição,
        bloqueio ou anonimização, em algumas circunstâncias, como para
        cumprimento de obrigações legais, contratuais, regulatórias e
        auditorias, para resguardar e exercer direitos da Prefeitura Municipal
        de Uberlândia, dos titulares, para prevenção de atos ilícitos e em
        processos judiciais, administrativos e arbitrais, inclusive por
        questionamento de terceiros sobre suas atividades e em outras hipóteses
        previstas em lei.
      </p>

      <p style={styles.paragraph}>
        O direito a portabilidade dos dados é um direito assegurado pela LGPD,
        contudo, cumpre informar que a transferência dos seus dados para outro
        prestador deverá ser precedida de uma validação prévia dos aspectos
        sistêmicos utilizados pelo prestador que será o receptor dos dados, de
        modo que haja eficácia nessa transferência.
      </p>

      <h2 style={styles.header}>COMPARTILHAMENTO DE DADOS</h2>
      <p style={styles.paragraph}>
        A Prefeitura Municipal de Uberlândia poderá compartilhar os seus dados
        pessoais a/quando:
      </p>
      <ul>
        <li style={styles.list}>
          Outras entidades para possibilitar a resposta aos pedidos de
          esclarecimento/comentários/sugestões que nos dirija, caso seja
          aplicável;
        </li>
        <li style={styles.list}>
          Entidades subcontratadas para os efeitos acima mencionados, nos termos
          dos contratos celebrados com as mesmas;
        </li>
        <li style={styles.list}>
          Tais comunicações de dados pessoais sejam necessárias ou adequadas:
        </li>

        <li style={styles.list}>à luz da legislação em vigor;</li>
        <li style={styles.list}>
          seja efetuada no âmbito do cumprimento de uma obrigação legal/ordem
          judicial;
        </li>
        <li style={styles.list}>
          de uma deliberação da Autoridade Nacional de Proteção de Dados – ANPD
          ou de outra autoridade de controle competente;
        </li>
        <li style={styles.list}>
          ou para responder a solicitações de autoridades públicas ou
          governamentais;
        </li>
        <li style={styles.list}>
          A comunicação seja necessária para proteger interesses vitais dos
          titulares ou qualquer outra finalidade legítima prevista na LGPD.
        </li>
      </ul>

      <h2 style={styles.header}>TRANSFERÊNCIA INTERNACIONAL DE DADOS</h2>
      <p style={styles.paragraph}>
        A Prefeitura Municipal de Uberlândia não aluga, vende e tampouco libera
        dados a terceiros com a finalidade de permitir qualquer comercialização
        de seus serviços, mas informa que seus dados poderão ser transferidos e
        mantidos em ambiente fora do seu município, estado ou país onde as leis
        de proteção de dados podem ser diferentes das vigentes no Brasil, mas
        asseguram grau de proteção de dados pessoais adequado ao previsto na
        LGPD.
      </p>
      <p style={styles.paragraph}>
        Tomaremos todas as medidas razoavelmente necessárias para garantir que
        seus dados sejam tratados de forma confiável, segura e de acordo com
        esta Política de Privacidade de Dados Pessoais.
      </p>

      <h2 style={styles.header}>POR QUANTO TEMPO MANTEMOS SEUS DADOS?</h2>
      <p style={styles.paragraph}>
        O prazo pelo qual a Prefeitura Municipal de Uberlândia mantém os dados
        pessoais coletados depende do propósito e da natureza do tratamento dos
        dados. Nós trataremos seus dados pelo período necessário para o
        cumprimento de obrigações legais, regulatórias e contratuais, para
        continuar a fornecer e aprimorar nossos produtos e serviços, para o
        gerenciamento de riscos, para o exercício regular de direito em
        processos administrativos, judiciais e arbitrais e para as demais
        finalidades previstas nesta Política de Privacidade de Dados Pessoais.
      </p>

      <h2 style={styles.header}>MEDIDAS DE SEGURANÇA</h2>
      <p style={styles.paragraph}>
        A Prefeitura Municipal de Uberlândia desenvolve os seus melhores
        esforços para proteger os dados pessoais dos titulares contra acessos
        não autorizados através da Internet e/ou violações a documentos físicos.
        Para o efeito utilizamos sistemas de segurança, políticas, normas e
        outros procedimentos, de modo:
      </p>
      <ul>
        <li style={styles.list}>A garantir a proteção dos dados pessoais;</li>
        <li style={styles.list}>
          A prevenir o acesso não autorizado aos dados, o uso impróprio, a sua
          divulgação, perda ou destruição.
        </li>
      </ul>
      <p style={styles.paragraph}>
        É, no entanto, da sua responsabilidade garantir e assegurar que a
        plataforma utilizada para acessar o nosso website se encontra
        adequadamente protegido contra softwares nocivos, vírus informáticos e
        worms.
      </p>

      <p style={styles.paragraph}>
        Recomendamos que o titular deve, também, zelar pelos próprios dados
        pessoais e adotar boas práticas para que não ocorram incidentes de
        segurança da informação e de dados pessoais. Todavia, se esses
        ocorrerem, comunique, de imediato, o Encarregado de Proteção de Dados
        Pessoais da controladora, nos termos da presente política.
      </p>

      <h2 style={styles.header}>RECLAMAÇÕES E DÚVIDAS</h2>
      <p style={styles.paragraph}>
        Para obter mais informações sobre a Política de Privacidade de Dados
        Pessoais ou sobre como tratamos os seus dados pessoais, você pode entrar
        em contato no e-mail contato.lgpd@uberlandia.mg.gov.br com o assunto
        “Aos cuidados do Encarregado”.
      </p>
      <p style={styles.paragraph}>
        A Prefeitura Municipal de Uberlândia informa que o prazo para resposta
        de reclamações e/ou dúvidas é de até 15 (quinze) dias.
      </p>
      <p style={styles.paragraph}>
        Caso tenha qualquer dúvida relacionada com o tratamento dos seus dados
        pessoais e com os direitos que lhe são conferidos pela legislação
        aplicável e, em especial, referidos nessa Política de Privacidade de
        Dados Pessoais, poderá acionar a Prefeitura Municipal de Uberlândia
        através do e-mail: contato.lgpd@uberlandia.mg.gov.br.
      </p>
      <p style={styles.paragraph}>
        O titular tem ainda o direito de apresentar uma reclamação à Autoridade
        Nacional de Proteção de Dados conforme previsto em lei, caso entenda que
        o tratamento dos seus dados pela Prefeitura Municipal de Uberlândia
        viola o regime legal em vigor a cada momento.
      </p>
    </div>
  );
}

export default PoliticaPrivacidade;
