import React from "react";
import logoProdaub from "../Assets/prodaub_logo.png";
import iconMenu from "../Assets/icon_menu.png";
import iconMenu_x from "../Assets/icon_menu_x.png";
import { Anchor } from "antd";
const Header = () => {
  const [menu, setMenu] = React.useState(false);
  return (
    <div
      className="headerCell"
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        paddingInline: "49px",
        zIndex: 10,
      }}
    >
      <img
        src={logoProdaub}
        width={227}
        height={50}
        alt="Logo Prodaub"
        onClick={() =>
          window.open("https://www.uberlandia.mg.gov.br/", "_blank").focus()
        }
        style={{ cursor: "pointer" }}
      />
      <img
        src={menu ? iconMenu_x : iconMenu}
        alt="menu"
        width={53}
        height={53}
        style={{ cursor: "pointer" }}
        onClick={() => setMenu(!menu)}
      />
      {menu && (
        <div
          className="menu"
          style={{
            position: "absolute",
            top: 69,
            right: 49,
            width: 652,
            height: 225,
            zIndex: 10,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              borderRadius: 10,
              zIndex: 10,
            }}
          >
            <div style={{ margin: 20 }}>
              <div>
                <h2 className="text" style={{ color: "#686868", fontSize: 24 }}>
                  LEI GERAL DE PROTEÇÃO DE DADOS
                </h2>
                <div
                  className="acnhor"
                  style={{
                    display: "inline-block",
                    fontSize: 14,
                    color: "#686868",
                    marginTop: -10,
                  }}
                >
                  <Anchor
                    direction="horizontal"
                    affix={false}
                    onClick={() => setMenu(false)}
                    style={{ color: "#686868" }}
                    items={[
                      {
                        key: "Sobre_a_LGPD",
                        href: "#Sobre_a_LGPD",
                        title: "Sobre a LGPD",
                      },
                      {
                        key: "Políticas_de_Privacidade",
                        href: "#Políticas_de_Privacidade",
                        title: "Política de Privacidade",
                      },
                      {
                        key: "Requisições",
                        href: "#Requisições",
                        title: "Requisições",
                      },
                      {
                        key: "Pergunta_e_Respostas_LGPD",
                        href: "#Pergunta_e_Respostas_LGPD",
                        title: "Pergunta e Respostas - LGPD",
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <h2 className="text" style={{ color: "#686868", fontSize: 24 }}>
                  LEI DE ACESSO À INFORMAÇÃO
                </h2>
                <div
                  className="acnhor"
                  style={{
                    display: "inline-block",
                    fontSize: 14,
                    color: "#686868",
                    marginTop: -10,
                  }}
                >
                  <Anchor
                    direction="horizontal"
                    affix={false}
                    onClick={() => setMenu(false)}
                    style={{ color: "#686868" }}
                    items={[
                      {
                        key: "Lei_de_Acesso_à_Informação",
                        href: "#Lei_de_Acesso_à_Informação",
                        title: "Lei de Acesso à Informação",
                      },
                      {
                        key: "Como_Solicitar_Informação",
                        href: "#Como_Solicitar_Informação",
                        title: "Como Solicitar Informação",
                      },
                      {
                        key: "Pergunta_e_Respostas_LAI",
                        href: "#Pergunta_e_Respostas_LAI",
                        title: "Pergunta e Respostas - LAI",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
