import api from "./api.ws.ts";

interface Protocolo {
  oid_protocolo: string;
  oid_dados_lgpd?: number;
  tp_documento: number;
  tp_status: string;
  ds_usu_alter?: string;
  dt_ult_alter?: string;
  vs_versao?: number;
}

export const putProtocolo = async (protocolo: Protocolo) => {
  try {
    const response = await api.put("/protocolo", protocolo);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postProtocolo = async (protocolo: Protocolo) => {
  try {
    const response = await api.post("/protocolo", protocolo);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteProtocolo = async (oid_protocolo: number) => {
  try {
    console.log("oid_protocolo", oid_protocolo);
    const response = await api.delete("/protocolo/" + oid_protocolo);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
