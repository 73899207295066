import React from "react";
import logoProdaub from "../Assets/prodaub_logo.png";
import iconGps from "../Assets/icon_gps.png";
import iconFacebook from "../Assets/face_logo.png";
import iconInstagram from "../Assets/instagram_logo.png";
import iconTwitter from "../Assets/twitter_logo.png";
const Footer = () => {
  return (
    <>
      <div
        className="footerCell"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "10%",
          color: "#fff",
          paddingBlock: 50,
        }}
      >
        <div>
          <img
            className="img"
            src={logoProdaub}
            width={405}
            height={89}
            onClick={() =>
              window.open("https://www.uberlandia.mg.gov.br/", "_blank").focus()
            }
            style={{ cursor: "pointer" }}
            alt="Logo Prodaub"
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 25 }}>
            <img src={iconGps} alt="gps" width={30} height={38} />
          </div>
          <div style={{ textAlign: "left" }}>
            <p>Prefeitura Municipal de Uberlândia</p>
            <p>Av. Anselmo Alves dos Santos,600 Santa</p>
            <p>Mônica Uberlândia - MG CEP 38408-150</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90vw",
          height: 1,
          backgroundColor: "#c6c6c6",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
        }}
      ></div>

      <div
        style={{
          textAlign: "start",
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "5%",
        }}
      >
        <p style={{ marginTop: 15 }}>Copyright @ 2024</p>
        <div>
          <img
            src={iconFacebook}
            alt="facebook"
            width={48}
            onClick={() =>
              window
                .open("https://www.facebook.com/PrefeituraUdi/", "_blank")
                .focus()
            }
            height={48}
            style={{ marginRight: 5, cursor: "pointer" }}
          />
          <img
            src={iconTwitter}
            alt="Twitter"
            width={48}
            height={48}
            onClick={() =>
              window
                .open("https://twitter.com/PrefeituraUdia", "_blank")
                .focus()
            }
            style={{ marginRight: 5, cursor: "pointer" }}
          />
          <img
            src={iconInstagram}
            alt="Instagram"
            width={48}
            height={48}
            onClick={() =>
              window
                .open(
                  "https://www.instagram.com/prefeituradeuberlandia/",
                  "_blank"
                )
                .focus()
            }
            style={{ marginRight: 5, cursor: "pointer" }}
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
