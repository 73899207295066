import React, { useState } from "react";
import iconDown from "../Assets/icon_down.png";
import cadeado from "../Assets/cadeado.png";
import muie from "../Assets/muie.png";
import omi from "../Assets/omi.png";
import { Button, Collapse, Form, Input, Modal, message } from "antd";
import { deleteProtocolo, putProtocolo } from "../Service/protocolo.ws.ts";
import { putDadosLgpd } from "../Service/dados_lgpd.ws.ts";
import ReactInputMask from "react-input-mask";
import { postEmail } from "../Service/email.ws.ts";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [protocolo, setProtocolo] = useState("");
  const [datahoje, setDataHoje] = useState("");
  const [form] = Form.useForm();
  const listEmail = ["gabrielsaimo68@gmail.com", "waldemar@systemid.com.br"];
  const [loading, setLoading] = useState(false);
  const numeros = protocolo.match(/\d{4}/);
  const [tp_documento, setTp_documento] = useState("");
  const [inputValue, setInputValue] = useState("");

  let resultado = "";
  if (numeros && numeros.length > 0) {
    resultado = parseInt(numeros[0], 10).toString();
  }
  const showModal = (type) => {
    setTp_documento(type);
    CreateProtocolo(type);
  };

  const handleCancel = async () => {
    await deleteProtocolo(resultado);
    setIsModalOpen(false);
    message.error("Requisição cancelada!");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const finalizar = () => {
    setLoading(true);
    form.resetFields();
  };
  const validarCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf === "") return false;
    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  };

  const verificar = async (values) => {
    try {
      const cpfValido = validarCPF(values.nr_cpf);

      if (cpfValido) {
        console.log("Success:", values);
        values.ds_observacao = (typeof values.ds_observacao == "undefined") ? "" : values.ds_observacao;
        const body = {
          oid_dados_lgpd: null,
          ds_nome: values.ds_nome,
          nr_cpf: values.nr_cpf.replace(/\D/g, ""),
          ds_email: values.ds_email,
          nr_telefone: values.nr_telefone.replace(/\D/g, ""),
          oid_protocolo: Number(resultado),
          ds_protocolo: protocolo,
          ds_observacao: values.ds_observacao,
        };

        const email_body = {
          destinatario: listEmail,
          assunto:
            tp_documento === "A"
              ? "REQUISIÇÃO PARA CONFIRMAÇÃO DA EXISTÊNCIA DE TRATAMENTO DE DADOS PESSOAIS"
              : tp_documento === "B"
                ? "REQUISIÇÃO DE ACESSO AOS SEUS DADOS PESSOAIS"
                : tp_documento === "C"
                  ? "REQUISIÇÃO PARA CORREÇÃO DE DADOS PESSOAIS"
                  : tp_documento === "D"
                    ? "REQUISIÇÃO PARA PORTABILIDADE DE DADOS PESSOAIS"
                    : "REQUISIÇÃO PARA EXCLUSÃO DE DADOS PESSOAIS",
          corpo: `<h1>${tp_documento === "A"
            ? "Requisição para confirmação da existência de tratamento de dados pessoais"
            : tp_documento === "B"
              ? "Requisição de acesso aos seus dados pessoais"
              : tp_documento === "C"
                ? "Requisição para correção de dados pessoais"
                : tp_documento === "D"
                  ? "Requisição para portabilidade de dados pessoais"
                  : "Requisição para exclusão de dados pessoais"
            }</h1>
                          <p>Nome: ${values.ds_nome}</p>
                          <p>CPF: ${values.nr_cpf}</p>
                          <p>E-mail: ${values.ds_email}</p>
                          <p>Telefone: ${values.nr_telefone}</p>
                          <p>Protocolo: ${protocolo}</p>
                          ${values.ds_observacao.length > 0 ?
              `<p>Obs: ${values.ds_observacao}</p>` : ""
            }
                          
                          `,
        };
        await postEmail(email_body);
        await putDadosLgpd(body);
        setIsModalOpen(false);
        setLoading(false);
        message.success("Requisição enviada com sucesso!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        console.log("CPF inválido!");
        message.error("CPF inválido! Por favor, insira um CPF válido.");
        setLoading(false);
      }
    } catch (e) {
      console.log("Failed:");
      setLoading(false);
      message.error("Erro ao enviar requisição!");
    }
  };

  const items1 = [
    {
      key: "1",
      label: "Do que trata a LGPD?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          A Lei Geral de Proteção de Dados Pessoais - LGPD (Lei n. 13.709, de
          2018) dispõe sobre o tratamento de dados pessoais das pessoas
          naturais, definindo as hipóteses em que tais dados podem legitimamente
          ser utilizados por terceiros e estabelecendo mecanismos para proteger
          os titulares dos dados contra usos inadequados. A Lei é aplicável ao
          tratamento de dados realizado por pessoas naturais ou por pessoas
          jurídicas de direito público ou privado, e tem, conforme o art 1º, o
          objetivo de proteger os direitos fundamentais de liberdade e de
          privacidade e o livre desenvolvimento da personalidade da pessoa
          natural.
        </p>
      ),
    },
    {
      key: "2",
      label: "O que são Dados Pessoais?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          Qualquer informação relacionada a pessoa natural identificada ou
          identificável. A legislação brasileira de privacidade e proteção de
          dados pessoais adota o conceito expansionista de dado pessoal, pelo
          qual não somente a informação relativa a pessoa diretamente
          identificada estará protegida pela lei, mas também aquela informação
          que possa tornar a pessoa identificável. Exemplos: Nome, prenome,
          número de RG, número de CPF, título de eleitor, CTPS, endereço
          eletrônico, endereço residencial, etc.
        </p>
      ),
    },
    {
      key: "3",
      label: "O que são Dados Pessoais Sensíveis?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          Dados pessoais sobre origem racial ou étnica, convicção religiosa,
          opinião política, filiação a sindicato ou a organização de caráter
          religioso, filosófico ou político, dado referente à saúde ou à vida
          sexual, dado genético ou biométrico, quando vinculado a uma pessoa
          natural. São dados pessoais que possam trazer algum tipo de
          discriminação quando do seu tratamento, ou seja, são dados pessoais
          que poderão implicar riscos e vulnerabilidades potencialmente mais
          gravosas aos direitos e liberdades fundamentais dos titulares.
        </p>
      ),
    },
    {
      key: "4",
      label: "Quais dados são protegidos pela LGPD?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          São todos os dados pessoais e ou dados pessoais sensíveis que permitem
          identificar determinada pessoa física.
        </p>
      ),
    },
  ];

  const items2 = [
    {
      key: "1",
      label: "O que é a Lei de Acesso à Informação - LAI?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          A Lei de Acesso à Informação foi instituída pela Lei Federal nº 12.527
          de 2011 e regulamenta o direito fundamental de todo cidadão de acesso
          a informações públicas previsto na Constituição Federal. Dessa forma,
          qualquer pessoa pode solicitar informações aos órgãos, entidades e
          empresas do Governo. O Decreto Estadual nº 45.969 de 25 de maio de
          2012 disciplina o acesso à informação no âmbito do Poder Executivo
          Estadual.
        </p>
      ),
    },
    {
      key: "2",
      label: "Quem pode solicitar informações?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          Qualquer interessado, pessoa física ou pessoa jurídica, poderá
          apresentar pedido de acesso a informações aos órgãos públicos
          integrantes da administração direta dos Poderes Executivo,
          Legislativo, incluindo as Cortes de Contas, e Judiciário e do
          Ministério Público, as autarquias, as fundações públicas, as empresas
          públicas, as sociedades de economia mista e demais entidades
          controladas direta ou indiretamente pela União, Estados, Distrito
          Federal e Municípios. O pedido pode ser formulado por qualquer meio
          legítimo, devendo este conter a identificação do requerente e a
          especificação da informação requerida.
        </p>
      ),
    },
    {
      key: "3",
      label: "Qual é o prazo para o fornecimento das informações?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          O órgão ou entidade pública deverá autorizar ou conceder o acesso
          imediato à informação disponível. Não sendo possível conceder o acesso
          imediato, o órgão ou entidade que receber o pedido deverá, em prazo
          não superior a 20 (vinte) dias: I - comunicar a data, local e modo
          para se realizar a consulta, efetuar a reprodução ou obter a certidão;
          II - indicar as razões de fato ou de direito da recusa, total ou
          parcial, do acesso pretendido; ou III - comunicar que não possui a
          informação, indicar, se for do seu conhecimento, o órgão ou a entidade
          que a detém, ou, ainda, remeter o requerimento a esse órgão ou
          entidade, cientificando o interessado da remessa de seu pedido de
          informação.
        </p>
      ),
    },
    {
      key: "4",
      label: "Este prazo poderá ser prorrogado?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          O prazo de 20 (vinte) dias pode ser prorrogado por mais 10 (dez) dias,
          mediante justificativa expressa, da qual será cientificado o
          requerente.
        </p>
      ),
    },
    {
      key: "5",
      label: "Qual o procedimento para registro de solicitações ou recursos?",
      children: (
        <p style={{ color: "#3179CB", fontSize: 16 }}>
          Recomendo que vocês descrevam como é o procedimento interno da
          Prefeitura, pois não tenho conhecimento.
        </p>
      ),
    },
    {
      key: "6",
      label: "Todas as informações são públicas?",
      children: (
        <>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            O Decreto 7.724/12, que regulamentou a LAI no Poder Executivo
            Federal, definiu como um dever dos entes governamentais a publicação
            na internet de um conjunto mínimo de informações públicas de
            interesse coletivo ou geral. Os órgãos e entidades deverão
            disponibilizar, em seus sítios eletrônicos, informações sobre:
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            a) estrutura organizacional e atribuições dos órgãos, lista dos
            principais cargos e seus respectivos ocupantes, endereços e
            telefones de suas unidades e horários de atendimento ao público;
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            b) programas, projetos, ações, obras e atividades, indicando a
            unidade responsável, principais metas e resultados e indicadores (se
            existirem);
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            c) repasses ou transferências de recursos financeiros;
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            d) execução orçamentária e financeira detalhada;
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            e) licitações, contratos e notas de empenho emitidas;
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            f) remuneração recebida por servidores e empregados públicos;
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            g) respostas a perguntas mais frequentes da sociedade;
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            h) contato da autoridade de monitoramento da LAI na instituição e
            informações sobre o Serviço de Informações ao Cidadão (SIC);
          </p>
          <p style={{ color: "#3179CB", fontSize: 16 }}>
            i) informações classificadas e desclassificadas.
          </p>
        </>
      ),
    },
  ];
  const obterDataFormatada = () => {
    const hoje = new Date();
    const dia = String(hoje.getDate()).padStart(2, "0");
    const mes = String(hoje.getMonth() + 1).padStart(2, "0");
    const ano = hoje.getFullYear();
    setDataHoje(`${dia}/${mes}/${ano}`);
    return dia + mes + ano;
  };
  const CreateProtocolo = async (type) => {
    setProtocolo("");
    const dataFormatada = obterDataFormatada();
    const body = {
      ds_protocolo: `${dataFormatada}_LGPD`,
      tp_documento: type,
      tp_status: "em Andamento",
    };
    const resp = await putProtocolo(body);
    setProtocolo(resp + `${dataFormatada}_LGPD`);
    setIsModalOpen(true);
    return;
  };

  const handleInputChange = (e) => {
    if (e.target.value.length <= 2000) {
      setInputValue(e.target.value);
    }
  };
  const validateLength = () => {

    if (inputValue.length <= 2000) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('O conteúdo deve ter menos de 2000 caracteres!'));
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "-45px",
      }}
    >
      <div className="div1 req" style={{ display: "flex" }}>
        <div style={{ zIndex: 1 }}>
          <div
            className="textrespon "
            style={{ marginLeft: "50px", width: 763 }}
          >
            <h1 className="h1mobi" style={{ fontSize: 80 }}>
              PROTEÇÃO
            </h1>
            <h1 className="h1mobi" style={{ fontSize: 80, marginTop: "-75px" }}>
              DE DADOS
            </h1>
            <h1 className="h1mobi" style={{ fontSize: 80, marginTop: "-75px" }}>
              PESSOAIS
            </h1>
            {/**<h1 className="h1mobi" style={{ fontSize: 80, marginTop: "-75px" }}>
              DE PRIVACIDADE
            </h1> */}
          </div>

          <div
            style={{
              marginLeft: "50px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h2 style={{ color: "#f3d10d", marginInlineEnd: 10, fontSize: 24 }}>
              Navegue e saiba mais
            </h2>
            <img
              src={iconDown}
              alt="down"
              width={9}
              height={15}
              style={{ marginTop: 5 }}
            ></img>
          </div>
        </div>

        <div>
          <img
            className="img"
            src={muie}
            alt="Mulher"
            width={822}
            height={705}
            style={{ marginLeft: "-286px", zIndex: -1, marginTop: "65px" }}
          ></img>
        </div>
      </div>

      <div
        id="Sobre_a_LGPD"
        className="div2 "
        style={{ marginLeft: "50px", marginTop: "-180px", width: "1299px" }}
      >
        <h2
          style={{
            fontSize: 24,
            backgroundColor: "#f3d10d",
            color: "#326eb6",
            width: 230,
            padding: 10,
            textAlign: "center",
            borderRadius: 30,
          }}
        >
          Sobre a LGPD
        </h2>
        <p className="p1" style={{ width: "660px", fontSize: 16 }}>
          A Lei Geral de Proteção de Dados (LGPD) é uma lei aprovada em agosto
          de 2018 no Brasil que impôs regras sobre o tratamento de dados
          pessoais e que tem como finalidade proteger o direito à liberdade,
          privacidade e livre desenvolvimento dos cidadãos. A LGPD visa proteger
          os direitos de liberdade e de privacidade, criando normas a serem
          seguidas por empresas e governos para a coleta e o tratamento de dados
          pessoais (como nome, CPF, endereço) e de dados sensíveis (como
          biometria e informações sobre política e religião).
        </p>
      </div>

      <div
        className="div3"
        style={{
          marginLeft: "50px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          className="img"
          src={cadeado}
          alt="Cadeado"
          style={{
            width: "700px",
            height: "525px",
            marginRight: "23px",
            marginLeft: "-280px",
          }}
        ></img>
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(window.location.origin + "/Política-de-Privacidade")
          }
        >
          <div id="Políticas_de_Privacidade" className="div4">
            <h2
              style={{
                fontSize: 24,
                backgroundColor: "#f3d10d",
                color: "#326eb6",
                width: "338px",
                padding: 10,
                textAlign: "center",
                borderRadius: 30,
                cursor: "pointer",
              }}
            >
              Política de Privacidade
            </h2>
            <b style={{ color: "black" }}>
              Clique{" "}
              <a
                href="/Política-de-Privacidade"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#326eb6" }}
              >
                aqui{" "}
              </a>
              para acessar a Política de Privacidade
            </b>
            <p className="p1" style={{ width: "467px", fontSize: 16 }}>
              A política de privacidade da PRODAUB é um dos instrumentos de
              compliance da PRODAUB, pois reflete o princípio da Transparência,
              garantindo aos titulares, informações claras, precisas e
              facilmente acessíveis sobre a realização do tratamento e os
              respectivos agentes de tratamento.
            </p>
          </div>
        </div>
      </div>
      <div
        className="div5 "
        style={{
          marginLeft: "50px",
          display: "flex",
          flexDirection: "column",
          width: "1121px",
        }}
      >
        <div id="Requisições" className="req">
          <h2
            style={{
              fontSize: 24,
              backgroundColor: "#f3d10d",
              color: "#326eb6",
              width: "230px",
              padding: 10,
              textAlign: "center",
              borderRadius: 30,
            }}
          >
            Requerimentos
          </h2>
        </div>
        <div className="req">
          <p>
            Caso deseje fazer algum requerimento, como acessar ou corrigir seus
            dados, por favor clique no botão abaixo e preencha o requerimento
            on-line.
          </p>
        </div>
        <div>
          <div
            className="div6 req"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div onClick={() => showModal("A")}>
              <h2
                style={{
                  fontSize: 14,
                  backgroundColor: "#EAEAED",
                  color: "#478CF7",
                  width: 202,
                  height: "50px",
                  padding: 10,
                  alignItems: "center",
                  borderRadius: 30,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                <p style={{ width: 151 }}>
                  Confirmação do Tratamento dos Dados Pessoais
                </p>
              </h2>
            </div>
            <div onClick={() => showModal("B")}>
              <h2
                style={{
                  fontSize: 14,
                  backgroundColor: "#EAEAED",
                  color: "#478CF7",
                  width: "202px",
                  height: "50px",
                  padding: 10,
                  alignItems: "center",
                  borderRadius: 30,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                <p style={{ width: 105 }}>Acesso aos Dados Pessoais</p>
              </h2>
            </div>
            <div onClick={() => showModal("C")}>
              <h2
                style={{
                  fontSize: 14,
                  backgroundColor: "#EAEAED",
                  color: "#478CF7",
                  width: "202px",
                  height: "50px",
                  padding: 10,
                  alignItems: "center",
                  borderRadius: 30,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                <p style={{ width: 105 }}>Correção dos Dados Pessoais</p>
              </h2>
            </div>
          </div>
          <div>
            <div
              className="div6 req"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div onClick={() => showModal("D")}>
                <h2
                  style={{
                    fontSize: 14,
                    backgroundColor: "#EAEAED",
                    color: "#478CF7",
                    width: "202px",
                    height: "50px",
                    padding: 10,
                    alignItems: "center",
                    borderRadius: 30,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <p style={{ width: 88 }}>Portabilidade de Dados Pessoais</p>
                </h2>
              </div>
              <div onClick={() => showModal("E")}>
                <h2
                  style={{
                    fontSize: 14,
                    backgroundColor: "#EAEAED",
                    color: "#478CF7",
                    width: "202px",
                    height: "50px",
                    padding: 10,
                    alignItems: "center",
                    borderRadius: 30,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <p style={{ width: 105 }}>Exclusão de Dados Pessoais</p>
                </h2>
              </div>
              <div>
                <h2
                  style={{
                    fontSize: 14,
                    backgroundColor: "#EAEAED",
                    color: "#478CF7",
                    width: "202px",
                    height: "50px",
                    padding: 10,
                    alignItems: "center",
                    borderRadius: 30,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <p style={{ width: 90 }}>Notificação de Incidentes</p>
                </h2>
              </div>
            </div>
          </div>
          <div
            className="div7 req"
            id="Pergunta_e_Respostas_LGPD"
            style={{
              width: 1121,
              height: 4,
              backgroundColor: "#FFD800",
              borderRadius: 20,
              marginBottom: 20,
              marginTop: 20,
            }}
          ></div>
        </div>

        <div
          className="div8 req"
          style={{
            width: 1086,
            backgroundColor: "#FFFF",
            textAlign: "center",
            borderRadius: 20,
          }}
        >
          <h1 className="textCell" style={{ color: "#3179CB" }}>
            Perguntas Frequentes LGPD
          </h1>
          <div style={{ textAlign: "start" }}>
            <Collapse
              defaultActiveKey={["1"]}
              collapsible="header"
              bordered={false}
              ghost
              items={items1}
              style={{ color: "#3179CB" }}
              expandIconPosition="right"
            />
          </div>
        </div>

        <div
          className="div9 "
          style={{ display: "flex", justifyContent: "end", marginBottom: 40 }}
        >
          <img
            className="img"
            src={omi}
            alt="Omi"
            style={{ width: 932, height: 932, marginTop: 20 }}
          />
          <div
            className="div10 req"
            id="Lei_de_Acesso_à_Informação"
            style={{ marginLeft: "-195px", marginTop: 120 }}
          >
            <div style={{ marginBottom: 50 }}>
              <h2
                className="nametg"
                style={{
                  fontSize: 24,
                  backgroundColor: "#f3d10d",
                  color: "#326eb6",
                  width: "484px",
                  padding: 10,
                  textAlign: "center",
                  borderRadius: 30,
                }}
              >
                Sobre a Lei de Acesso à Informação - LAI
              </h2>
              <p className="p1" style={{ width: "575px", fontSize: 16 }}>
                A Lei de Acesso à Informação (LAI) é uma lei federal que entrou
                em vigor em 2012. Ela regulamenta o direito constitucional de
                acesso às informações públicas e cria mecanismos que
                possibilitam a qualquer pessoa, física ou jurídica, sem
                necessidade de apresentar motivo, o acesso a informações
                públicas. A LAI estabelece que as informações referentes à
                atividade do Estado são públicas, exceto aquelas expressas na
                legislação1234
              </p>
            </div>
            <div
              style={{ height: 50, width: 50 }}
              id="Como_Solicitar_Informação"
            ></div>
            <div>
              <h2
                className="nametg"
                style={{
                  fontSize: 24,
                  backgroundColor: "#f3d10d",
                  color: "#326eb6",
                  width: "383px",
                  padding: 10,
                  textAlign: "center",
                  borderRadius: 30,
                }}
              >
                Como Consultar Informação
              </h2>
              <p className="p1" style={{ width: "597px", fontSize: 16 }}>
                Para solicitar informações pela Lei de Acesso à Informação
                (LAI), você pode acessar o Sistema Eletrônico do Serviço de
                Informação ao Cidadão (e-SIC) tanto online quanto
                presencialmente. Para fazer um pedido via LAI, você precisará se
                cadastrar no e-SIC e seguir os seguintes passos12:
              </p>
              <p> Acesse o e-SIC.</p>
              <p>Selecione “Acesso à Informação”.</p>
              <p>Faça login.</p>
              <p>Identifique-se.</p>
              <p>Escolha o órgão.</p>
              <p>Descreva o pedido.</p>
              <p>Click em “Avançar”, no canto inferior direito.</p>
              <p>Revise o pedido.</p>
            </div>
          </div>
        </div>
        <div id="Pergunta_e_Respostas_LAI" style={{ height: 20 }}></div>

        <div
          className="div8 req"
          style={{
            width: 1086,
            backgroundColor: "#F2D30D",
            textAlign: "center",
            borderRadius: 20,
            marginBottom: 80,
          }}
        >
          <h1 className="textCell" style={{ color: "#3179CB" }}>
            Perguntas Frequentes LAI
          </h1>
          <div style={{ textAlign: "start" }}>
            <Collapse
              defaultActiveKey={["1"]}
              collapsible="header"
              bordered={false}
              ghost
              items={items2}
              style={{ color: "#3179CB" }}
              expandIconPosition="right"
            />
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        width={1086}
      >
        <div
          className="modal_h"
          style={{ height: "75vh", overflow: "auto", overflowX: "hidden" }}
        >
          <div>
            <h1
              style={{
                color: "#707070",
                fontSize: 17,
                textAlign: "center",
                marginBottom: 40,
              }}
            >
              {tp_documento === "A"
                ? "CONFIRMAÇÃO DA EXISTÊNCIA DE TRATAMENTO DE DADOS PESSOAIS"
                : tp_documento === "B"
                  ? "REQUISIÇÃO DE ACESSO AOS SEUS DADOS PESSOAIS"
                  : tp_documento === "C"
                    ? "REQUISIÇÃO PARA CORREÇÃO DE DADOS PESSOAIS"
                    : tp_documento === "D"
                      ? "REQUISIÇÃO PARA PORTABILIDADE DE DADOS PESSOAIS"
                      : "REQUISIÇÃO PARA EXCLUSÃO DE DADOS PESSOAIS "}
            </h1>
            <p className="text">
              Recebemos sua solicitação de{" "}
              {tp_documento === "A"
                ? "CONFIRMAÇÃO DA EXISTÊNCIA DE DADOS PESSOAIS "
                : tp_documento === "B"
                  ? "ACESSO AOS SEUS DADOS PESSOAIS "
                  : tp_documento === "C"
                    ? "CORREÇÃO DE DADOS PESSOAIS "
                    : tp_documento === "D"
                      ? "PORTABILIDADE DE DADOS PESSOAIS "
                      : "ELIMINAÇÃO DE DADOS PESSOAIS "}
              tratados pela PRODAUB - PROCESSAMENTO DE DADOS DE UBERLÂNDIA em{" "}
              <b>{datahoje}</b> e registramos com o protocolo de número{" "}
              <b>{protocolo}</b>
            </p>
            <p className="text">
              Para assegurar a proteção dos seus dados pessoais, precisamos
              confirmar sua identidade. Para isso, complete as informações*
              abaixo:
            </p>
          </div>
          <div className="formModal" style={{ marginInline: 75 }}>
            <Form
              layout="vertical"
              name="body"
              autoComplete="on"
              onFinish={async (values) => {
                verificar(values);
              }}
              onFinishFailed={(errorInfo) => {
                console.log("Failed:", errorInfo);
              }}
            >
              <Form.Item
                label="Nome Completo*"
                name="ds_nome"
                rules={[
                  { required: true, message: "Por favor, insira seu nome!" },
                ]}
                style={{ fontWeight: "bold" }}
              >
                <Input
                  placeholder="Digite o nome"
                  style={{
                    width: "100%",
                  }}
                ></Input>
              </Form.Item>
              <div
                className="formModal1"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Form.Item
                  label="CPF*"
                  name="nr_cpf"
                  rules={[
                    { required: true, message: "Por favor, insira seu CPF!" },
                  ]}
                  style={{ fontWeight: "bold" }}
                >
                  <ReactInputMask
                    mask="999.999.999-99"
                    placeholder="Digite o CPF"
                    className="ant-input textrespon1"
                    style={{
                      width: 150,
                    }}
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </ReactInputMask>
                </Form.Item>
                <Form.Item
                  label="E-mail*"
                  name="ds_email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira seu e-mail!",
                    },
                  ]}
                  style={{ fontWeight: "bold" }}
                >
                  <Input
                    placeholder="Digite o e-mail"
                    className="textrespon1"
                    style={{
                      width: 500,
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label="Telefone*"
                  name="nr_telefone"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira seu telefone!",
                    },
                  ]}
                  style={{ fontWeight: "bold" }}
                >
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    placeholder="Digite o telefone"
                    className="ant-input textrespon1"
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </ReactInputMask>
                </Form.Item>


              </div>
              <Form.Item
                label="Observação*"
                name="ds_observacao"
                rules={[
                  { validator: validateLength, message: "Por favor, insira sua observação com até 2000 caracteres!" },
                ]}
                style={{ fontWeight: "bold" }}
              >
                <Input.TextArea
                  placeholder="Digite sua observação"
                  defaultValue={''}
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                  }}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  maxLength={2000}
                />
              </Form.Item>
              <div style={{ position: 'relative', marginBottom: 24 }}>
                <div style={{ position: 'absolute', right: 0, top: -24, color: inputValue.length > 1999 ? 'red' : 'black' }}>
                  <b>{inputValue.length}/2000</b>
                </div>
              </div>

              <div
                className="formModal1"
                style={{ display: "flex", justifyContent: "space-between" }}
              >

                <Form.Item label=" ">
                  <Button
                    loading={loading}
                    htmlType="submit"
                    onClick={() => finalizar()}
                    className="button"
                  >
                    Salvar
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <p className="text">
            *Não se preocupe, manteremos esses arquivos armazenados de maneira
            protegida e somente pelo período apropriado para atendimento aos
            órgãos reguladores e legislações vigentes.
          </p>
          <p className="text">
            {" "}
            {tp_documento === "A"
              ? "Ressaltamos que ao responder essa mensagem com as informações solicitadas, você confirmará o exercício de seu direto de ter acesso à confirmação de existência de dados pessoais em nossos cadastros"
              : tp_documento === "B"
                ? "Ressaltamos que ao responder essa mensagem com as informações solicitadas, você confirmará o exercício de seu direto de ter acesso a seus dados pessoais tratados pela PRODAUB - PROCESSAMENTO DE DADOS DE UBERLÂNDIA."
                : tp_documento === "C"
                  ? "Ressaltamos que ao responder essa mensagem com as informações solicitadas, você confirmará o exercício de seu direto de retificar, alterar e/ou corrigir seus dados pessoais em nossos cadastros"
                  : tp_documento === "D"
                    ? "Ressaltamos que ao responder essa mensagem com as informações solicitadas, você confirmará o exercício de seu direto de portabilidade de seus dados pessoais existentes em nossos cadastros"
                    : "Ressaltamos que ao responder essa mensagem com as informações solicitadas, você confirmará o exercício de seu direto de ter seus dados pessoais eliminados de nossos cadastros e encerrará seu relacionamento com a PRODAUB - PROCESSAMENTO DE DADOS DE UBERLÂNDIA. Um novo relacionamento pode ser iniciado a qualquer momento, por sua vontade, quando será necessário um novo cadastro"}
            .
          </p>
          <p className="text">
            A partir do recebimento de todas as informações solicitadas acima,
            confirmaremos sua identidade e retornaremos sua solicitação de
            confirmação da atividade de tratamento dos seus dados pessoais em
            até 15 (quinze) dias.
          </p>
          <p className="text">
            Caso não retorne essa mensagem com as informações solicitadas em até
            15 (quinze) dias, consideraremos esta solicitação encerrada por
            falta de confirmação.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
