import React from "react";
import "../App.css";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Home from "../Pages/Home";
function App() {
  return (
    <>
      <header>
        <Header />
      </header>
      <body>
        <Home />
      </body>
      <footer className="footer">
        <Footer />
      </footer>
    </>
  );
}

export default App;
