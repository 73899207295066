import api from "./api.ws.ts";

interface DadosLgpd {
  oid_dados_lgpd: number;
  ds_nome: string;
  nr_cpf: number;
  ds_email: string;
  nr_telefone: number;
  ds_usu_alter?: string;
  dt_ult_alter?: string;
  vs_versao?: number;
}

export const putDadosLgpd = async (dados_lgpd: DadosLgpd) => {
  try {
    const response = await api.put("/dados-lgpd", dados_lgpd);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
