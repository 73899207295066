import api from "./api.ws.ts";

interface Email_inteface {
  destinatario: any;
  assunto: string;
  corpo: string;
}

export const postEmail = async (corpoEmail: Email_inteface) => {
  try {
    const response = await api.post("/email/enviar", corpoEmail);
    return response.data;
  } catch (error) {
    console.error(error);
    alert("Erro ao enviar email");
  }
};
